import Link from 'next/link';

import data from './data.json';
import styles from './SoftballRankings.module.scss';

export interface SoftballRankingsProps {
  rank: number;
  previousRank: number | null;
  teamName: string;
  teamMascot: string;
  teamSlug: string;
  teamLogo: string;
  record: string | null;
  conference: string;
}

export const SoftballRankings = () => {
  return (
    <div className={styles.block}>
      <h2>Softball Rankings</h2>
      <section className={styles.rankings}>
        {data.slice(0, 10).map((ranking: SoftballRankingsProps) => (
          <Link
            className={styles.ranking}
            href={`/college/${ranking.teamSlug}/`}
            key={ranking.rank}
          >
            <div className={styles.rank}>{ranking.rank}</div>
            <div className={styles.team}>
              <figure>
                <img
                  alt={`${ranking.teamName} ${ranking.teamMascot} Logo`}
                  src={ranking.teamLogo}
                />
              </figure>
              <div>
                <span className={styles.name}>{ranking.teamName}</span>
                <span>{ranking.teamMascot}</span>
              </div>
            </div>
            <div className={styles.rating}>
              <span>{ranking.record}</span>
            </div>
          </Link>
        ))}
      </section>
      <div>
        <Link className={styles.viewAll} href={`/softball/team-rankings/`}>
          Complete Rankings
        </Link>
      </div>
    </div>
  );
};
