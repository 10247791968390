import {
  rdbV1NilRankingsList,
  v1CategoriesHeadlinesDetail2,
  type V1CategoriesHeadlinesDetail2Params,
} from '@on3/api';
import type { IGenericPageProps } from '@on3/ui-lib/api/schema/custom-contracts';
import withAuthentication from '@on3/ui-lib/src/hocs/withAuthentication';
import withValidUrl from '@on3/ui-lib/src/hocs/withValidUrl';
import { getFulfilledValue, proxyApi } from '@on3/ui-lib/utils/api';
import type { AxiosError } from 'axios';
import {
  SoftballAmericaHome,
  type SoftballAmericaHomeProps,
} from 'components/Home/SoftballAmerica/SoftballAmericaHome';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import type { GetServerSideProps } from 'next';

const title = `Softball America | The Top Source for Softball Stories, Rankings, and News`;
const description = 'The Top Source for Softball Stories, Rankings, and News';

interface ISoftballAmericaHome
  extends SoftballAmericaHomeProps,
    IGenericPageProps {}

const SoftballAmericaHomePage = (props: ISoftballAmericaHome) => {
  return (
    <PageWrapper
      {...props}
      canonical="https://www.on3.com/softball/"
      description={description}
      title={title}
    >
      <SoftballAmericaHome {...props} />;
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const softballAmericaSiteKey = 401;
  const siteData = await withValidUrl.isValid(ctx, softballAmericaSiteKey);
  const userData = await withAuthentication.isAuth(ctx);
  const softballSportKey = 12;

  const nilParams = {
    limit: 5,
    sportKey: softballSportKey,
  };

  const mainCategoryParams: V1CategoriesHeadlinesDetail2Params = {
    category: 552031,
  };
  const collegeCategoryParams: V1CategoriesHeadlinesDetail2Params = {
    category: 552033,
    limit: 5,
  };
  const recruitingCategoryParams: V1CategoriesHeadlinesDetail2Params = {
    category: 552037,
    limit: 5,
  };
  const topStoriesCategoryParams: V1CategoriesHeadlinesDetail2Params = {
    category: 552043,
    limit: 5,
  };
  const proCategoryParams: V1CategoriesHeadlinesDetail2Params = {
    category: 552036,
    limit: 5,
  };

  try {
    const [
      latestArticles,
      collegeArticles,
      topStories,
      recruitingNews,
      proNews,
      nil100,
    ] = await Promise.allSettled([
      v1CategoriesHeadlinesDetail2(proxyApi, mainCategoryParams),
      v1CategoriesHeadlinesDetail2(proxyApi, collegeCategoryParams),
      v1CategoriesHeadlinesDetail2(proxyApi, topStoriesCategoryParams),
      v1CategoriesHeadlinesDetail2(proxyApi, recruitingCategoryParams),
      v1CategoriesHeadlinesDetail2(proxyApi, proCategoryParams),
      rdbV1NilRankingsList(proxyApi, nilParams),
    ]);

    return {
      props: {
        pageType: 'home',
        siteData: siteData || [],
        userData: userData || {},
        latestArticles: getFulfilledValue(latestArticles),
        recruitingNews: getFulfilledValue(recruitingNews),
        collegeArticles: getFulfilledValue(collegeArticles),
        topStories: getFulfilledValue(topStories),
        proNews: getFulfilledValue(proNews),
        nil100: getFulfilledValue(nil100),
      },
    };
  } catch (error) {
    const err: AxiosError<unknown> = error as AxiosError<unknown>;

    console.log(err);

    return { props: {} };
  }
};

export default withValidUrl(SoftballAmericaHomePage);
